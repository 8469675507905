import React from "react"
import PropTypes from "prop-types"
import { Col, Row, Carousel } from "react-bootstrap"
import testimonyPic from "../../static/images/testimonial-pic.svg"

const Testimonials = ({ siteTitle }) => {
  return (
      <Row className="testimonial-container d-flex flex-row align-items-center mx-0 p-0">
        <Carousel className="mx-0" controls={false}>
          <Carousel.Item>
            <Row className="py-5">
              <Col xs={12} md={6} lg={6} className="d-flex flex-column justify-content-center align-items-center">
                <img
                    src={testimonyPic}
                    className="rounded-circle"
                    style={{width: "20em", height: "20em"}}
                    alt="testimony-pic"
                />
                <p className="fw-bolder mt-3 pl-5" style={{fontSize: "1.5em"}}>Viviana Angulo</p>
                <p className="fw-bold mt-0 pl-5" style={{fontSize: "1.2em"}}>Co-Founder Pista8 </p>
              </Col>
              <Col xs={12} md={6} lg={6} className="d-flex flex-row align-items-center justify-content-center justify-content-md-start justify-content-lg-start">
                <p
                    className="text-justify col-10 col-md-6 col-lg-7 fw-bold"
                    style={{
                      fontSize: "1.5em",
                      lineHeight: "2em"
                    }}
                >
                  “With Team Coaches, we were able to organize and coordinate all activities into one place.
                  The startups were grateful of the implementation and the centralization of the communication.
                  The team is passionate and always available to help implementing new features and improving their product”
                </p>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Row>
  )
}

Testimonials.propTypes = {
  siteTitle: PropTypes.string,
}

Testimonials.defaultProps = {
  siteTitle: ``,
}

export default Testimonials
