import React from "react"
import Footer from "../components/footer"
import Layout from "../components/layout"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import Testimonials from "../components/testimonials";
import etcCompanyLogo from "../../static/images/etc-logo.svg";
import ingenioCompanyLogo from "../../static/images/ingenio-logo.svg";
import newtopiaCompanyLogo from "../../static/images/newtopia-logo.svg";
import heroMeetings from "../../static/images/HeroMeeting.svg";
import {Button, Col, NavLink, Row} from "react-bootstrap";
import AppIFrame from "../components/AppIFrame";
import NewHero from "../components/hero/newHero";

const clientCompanyLogoStyles = { height: "3em", margin: "1em" };

const BookADemo = ({ location }) => {

    return (
        <Layout>
            <Seo title="Team Coaches Book A Demo" />
            <NavBar location={location} isBookDemoPage />
            <NewHero
                heroClasses="p-5"
                title="Innovate with an all in one platform for Innovators"
                description="Discover the IRM software just for your innovation project"
                descClasses="my-2 my-md-3 my-lg-3"
                descStyles={{ fontSize: "1.5em" }}
                extraDescription="Book your 30 minutes call with us"
                extraDescStyles={{color: "#3085C4", fontSize: "1.2em"}}
                buttonText="Book your free 30-min Demo"
                image={heroMeetings}
                altText="hero meeting"
                isDemoPage
            />
            <Row
                className="d-flex flex-column px-5 justify-content-center mx-0"
                style={{
                    backgroundColor: "#1F7ABF",
                    minHeight: "15em",
                    color: "white"
                }}
            >
                <Col
                    className="h3 fw-bolder text-white d-flex flex-row align-items-center justify-content-center"
                    style={{fontSize: "2em"}}
                >
                    Companies we have helped innovate
                </Col>
                <Col
                    className="
                        d-flex flex-row flex-wrap justify-content-center justify-content-lg-around
                        justify-content-md-around mb-4
                    "
                >
                    <img src={etcCompanyLogo} alt="etc-company-logo" style={clientCompanyLogoStyles}/>
                    <img src={newtopiaCompanyLogo } alt="newtopia-company-logo" style={clientCompanyLogoStyles}/>
                    <img src={ingenioCompanyLogo } alt="ingenio-company-log" style={clientCompanyLogoStyles}/>
                </Col>
            </Row>
            <AppIFrame
                sectionId="bool-a-zcal-demo"
                iframeId="book-a-demo-iframe"
                src="https://zcal.co/i/7Fsu6VI2"
                title="book a meeting"
                iFrameStyles={{ width: "100%", backgroundColor: "#F1F1F1" }}
            />
            <Testimonials />
            <Row
                className="d-flex flex-column px-5 align-items-center justify-content-center mx-0"
                style={{
                    backgroundColor: "#FFCB27",
                    minHeight: "20em"
                }}
            >
                <Col className="h3 fw-bolder d-flex flex-row align-items-center justify-content-center" style={{fontSize: "2.2em"}}>
                    Innovate your project and accelerate your goals with a click
                </Col>
                <Col className="d-flex flex-row justify-content-center">
                    <NavLink href="#book-a-zcal-demo" className="col-6 col-md-3 col-lg-3">
                        <Button
                            variant="secondary"
                            className="w-100 fw-bold p-2"
                            style={{
                                background: "#F1F1F1",
                                color: "black",
                                borderColor: "#F1F1F1",
                                fontSize: "1.2em"
                            }}>
                            Let’s Talk
                        </Button>
                    </NavLink>
                </Col>
            </Row>
            <Footer />
        </Layout>
    )
}

export default BookADemo
