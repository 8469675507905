import React from "react";

const AppIFrame = ({ sectionId, iframeId, src, title, iFrameStyles }) => {
     return (
         <section id={sectionId} className="mx-0">
             <iframe
                 id={iframeId}
                 src={src}
                 title={title}
                 style={iFrameStyles}
             />
         </section>
     )
}

export default AppIFrame;
